// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-base-js": () => import("./../../../src/pages/index-base.js" /* webpackChunkName: "component---src-pages-index-base-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mentions-jsx": () => import("./../../../src/pages/mentions.jsx" /* webpackChunkName: "component---src-pages-mentions-jsx" */),
  "component---src-pages-merci-jsx": () => import("./../../../src/pages/merci.jsx" /* webpackChunkName: "component---src-pages-merci-jsx" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */),
  "component---src-templates-project-template-project-template-js": () => import("./../../../src/templates/project-template/project-template.js" /* webpackChunkName: "component---src-templates-project-template-project-template-js" */)
}

